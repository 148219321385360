<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col>
          <h1>Breeds</h1>
        </v-col>
        <v-col cols="8">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed color="accent" @click="breedDialog.open = true">
              <v-icon left>mdi-plus</v-icon>Add Breed
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="breeds"
            :items-per-page="-1"
            no-data-text="No breeds found"
          >
            <template v-slot:item.size="{ item }">
              {{ item.size.charAt(0).toUpperCase() + item.size.slice(1) }}
            </template>
            <template v-slot:item.weight="{ item }">
              {{ item.min_weight }} - {{ item.max_weight }}kg
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="openEditBreed(item)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Breed</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    class="ml-2"
                    @click="deleteBreed(item)"
                  >
                    <v-icon x-small>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete Breed</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="breedDialog.open"
      max-width="600px"
      @click:outside="resetBreedDialog()"
    >
      <v-card>
        <v-form @submit.prevent="saveBreed" method="post">
          <v-card-title>
            <span class="headline">{{
              breedDialog.isEditing
                ? `Edit ${breedDialog.breed.name}`
                : "Add New Breed"
            }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              label="Name"
              type="text"
              v-model="breedDialog.fields.name"
              required
              outlined
              dense
              :error="breedDialog.errors.hasOwnProperty('name')"
              :error-messages="breedDialog.errors['name']"
            ></v-text-field>
            <v-select
              label="Size"
              v-model="breedDialog.fields.size"
              :items="sizes"
              item-text="text"
              item-value="value"
              required
              outlined
              dense
              :error="breedDialog.errors.hasOwnProperty('size')"
              :error-messages="breedDialog.errors['size']"
            ></v-select>
            <v-text-field
              label="Min Weight"
              type="number"
              v-model="breedDialog.fields.min_weight"
              required
              outlined
              dense
              :error="breedDialog.errors.hasOwnProperty('min_weight')"
              :error-messages="breedDialog.errors['min_weight']"
            ></v-text-field>
            <v-text-field
              label="Max Weight"
              type="number"
              v-model="breedDialog.fields.max_weight"
              required
              outlined
              dense
              :error="breedDialog.errors.hasOwnProperty('max_weight')"
              :error-messages="breedDialog.errors['max_weight']"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="resetBreedDialog()"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="breedDialog.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteBreedDialog.open"
      max-width="400px"
      @click:outside="resetDeleteBreed()"
    >
      <v-card>
        <v-card-title class="headline"
          >Delete {{ deleteBreedDialog.breedName }}</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-alert type="error" dense v-if="deleteBreedDialog.error"
            >There was an error when attempting to delete this breed.</v-alert
          >Are you sure you want to delete this breed?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetDeleteBreed()"
            >No, Cancel</v-btn
          >
          <v-btn
            color="accent"
            text
            @click="confirmDeleteBreed()"
            :loading="deleteBreedDialog.loading"
            >Yes, Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appId: this.$route.params.id,
      tableHeaders: [
        { text: "Breed", value: "name" },
        { text: "Size", value: "size" },
        { text: "Target Adult Weight", value: "weight" },
        { text: "Actions", value: "actions", align: "right" },
      ],
      breedDialog: {
        open: false,
        fields: {
          name: null,
          size: "medium",
          min_weight: null,
          max_weight: null,
        },
        loading: false,
        isEditing: false,
        breed: {},
        errors: {},
      },
      deleteBreedDialog: {
        open: false,
        error: false,
        breedName: "",
        breedId: null,
        loading: false,
      },
      sizes: [
        { text: "Small", value: "small" },
        { text: "Medium", value: "medium" },
        { text: "Large", value: "large" },
      ],
    };
  },

  computed: {
    breeds() {
      return this.$store.state.paw["breeds"];
    },
  },

  methods: {
    saveBreed: function () {
      const appId = this.$route.params.id;

      this.breedDialog.loading = true;
      this.breedDialog.errors = {};

      let payload = {
        appId,
        ...this.breedDialog.fields,
        isEditing: this.breedDialog.isEditing,
      };

      if (this.breedDialog.isEditing) {
        payload.breedId = this.breedDialog.breed.id;
      }

      this.$store
        .dispatch("paw/saveBreed", {
          ...payload,
        })
        .then(() => {
          this.resetBreedDialog();
        })
        .catch((err) => {
          this.breedDialog.errors = err.response.data.errors;
          this.breedDialog.loading = false;
        });
    },
    openEditBreed: function (breed) {
      this.breedDialog.fields.name = breed.name;
      this.breedDialog.fields.size = breed.size;
      this.breedDialog.fields.min_weight = breed.min_weight;
      this.breedDialog.fields.max_weight = breed.max_weight;
      this.breedDialog.breed = breed;
      this.breedDialog.isEditing = true;
      this.breedDialog.open = true;
    },
    resetBreedDialog: function () {
      this.breedDialog = {
        open: false,
        fields: {
          name: null,
          size: null,
          min_weight: null,
          max_weight: null,
        },
        loading: false,
        isEditing: false,
        breed: {},
        errors: {},
      };
    },
    deleteBreed: function (breed) {
      this.deleteBreedDialog.breedName = breed.name;
      this.deleteBreedDialog.breedId = breed.id;
      this.deleteBreedDialog.open = true;
    },
    confirmDeleteBreed: function () {
      const breedId = this.deleteBreedDialog.breedId;
      const appId = this.$route.params.id;
      this.deleteBreedDialog.loading = true;
      this.deleteBreedDialog.error = false;

      this.$store
        .dispatch("paw/deleteBreed", { appId, breedId })
        .then(() => {
          this.resetDeleteBreed();
        })
        .catch(() => {
          this.deleteBreedDialog.loading = false;
          this.deleteBreedDialog.error = true;
        });
    },
    resetDeleteBreed: function () {
      this.deleteBreedDialog = {
        open: false,
        error: false,
        breedName: "",
        breedId: null,
        loading: false,
      };
    },
  },
};
</script>
